<style>
	.el-tabs__header {
		background: #fff;
		padding: 0 20px;
	}
	.catb {
		box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);
		padding: 12px;
		text-align: left;
		color: #333;
		background: url(../../assets/mask_blue.png) no-repeat 100% 0;
		background-size: 100% 100%;
		border-radius: 6px;
	}
	/* .cat p { */
	/* text-shadow: 0 2px 12px rgb(0 0 0 / 30%); */
	/* } */
	.title {
		color: #fff;
		font-size: 14px;
	}
</style>
<template>
	<div style="background: linear-gradient(150deg, #2c6dd2 50%, #5eb361);min-height:100%;">
		<!-- <h1 style="color:#fff;text-align:center">商用密码认证产品
		</h1> -->

		<el-tabs>
			<el-tab-pane label="检测认证产品(1923个)" style="padding:0px 15px">
				<h4 class="title">第一批(22类)</h4>
				<el-row :gutter="12">
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,1)">
							<p style="font-weight:500;font-size:14px;margin:0">智能密码钥匙</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品87个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,2)">
							<p style="font-weight:500;font-size:14px;margin:0">智能IC卡</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品50个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,3)">
							<p style="font-weight:500;font-size:14px;margin:0">POS、ATM等终端</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品73个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,4)">
							<p style="font-weight:500;font-size:14px;margin:0">PCI-E/PCI密码卡</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品118个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,5)">
							<p style="font-weight:500;font-size:14px;margin:0">IPSec VPN产品/网关</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品199个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,6)">
							<p style="font-weight:500;font-size:14px;margin:0">SSL VPN产品/网关</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品175个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,7)">
							<p style="font-weight:500;font-size:14px;margin:0">安全认证网关</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品36个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,8)">
							<p style="font-weight:500;font-size:14px;margin:0">密码键盘</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品20个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,9)">
							<p style="font-weight:500;font-size:14px;margin:0">金融数据密码机</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品38个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,10)">
							<p style="font-weight:500;font-size:14px;margin:0">服务器密码机</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品135个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,11)">
							<p style="font-weight:500;font-size:14px;margin:0">签名验签服务器</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品106个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,12)">
							<p style="font-weight:500;font-size:14px;margin:0">时间戳服务器</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品64个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,13)">
							<p style="font-weight:500;font-size:14px;margin:0">安全门禁系统</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品14个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,14)">
							<p style="font-weight:500;font-size:14px;margin:0">动态令牌及认证系统</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品15个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,15)">
							<p style="font-weight:500;font-size:14px;margin:0">安全电子签章系统</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品68个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,16)">
							<p style="font-weight:500;font-size:14px;margin:0">电子文件密码应用系统</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品3个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,17)">
							<p style="font-weight:500;font-size:14px;margin:0">可信计算密码支撑平台</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品0个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,18)">
							<p style="font-weight:500;font-size:14px;margin:0">证书认证及密钥管理</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品102个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,19)">
							<p style="font-weight:500;font-size:14px;margin:0">对称密钥管理产品</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品16个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,20)">
							<p style="font-weight:500;font-size:14px;margin:0">安全芯片</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品135个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,21)">
							<p style="font-weight:500;font-size:14px;margin:0">电子标签芯片</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品16个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,22)">
							<p style="font-weight:500;font-size:14px;margin:0">其他密码模块</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品536个</p>
						</div>
					</el-col>
				</el-row>
				<h4 class="title">第二批(6类)</h4>
				<el-row :gutter="12">
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,23)">
							<p style="font-weight:500;font-size:14px;margin:0">可信计算密码模块</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品3个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,24)">
							<p style="font-weight:500;font-size:14px;margin:0">智能IC卡密钥管理系统</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品0个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,25)">
							<p style="font-weight:500;font-size:14px;margin:0">云服务器密码机</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品6个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,26)">
							<p style="font-weight:500;font-size:14px;margin:0">随机数发生器</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品0个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,27)">
							<p style="font-weight:500;font-size:14px;margin:0">区块链密码模块</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品1个</p>
						</div>
					</el-col>
					<el-col :span="12" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(1,28)">
							<p style="font-weight:500;font-size:14px;margin:0">安全浏览器密码模块</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品0个</p>
						</div>
					</el-col>
				</el-row>
			</el-tab-pane>
			<el-tab-pane label="证书换发产品(1622个)" style="padding:0px 15px">
				<h4 class="title">S密码<span style="color:#faad14">算</span>法类：密码"芯片"</h4>
				<el-row :gutter="12">
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SSX')">
							<p style="font-weight:500;font-size:14px;margin:0">芯片SSX</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品164个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SSM')">
							<p style="font-weight:500;font-size:14px;margin:0">模块SSM</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品1个</p>
						</div>
					</el-col>
				</el-row>
				<h4 class="title">J数据<span style="color:#faad14">加</span>解密类：服务器密码机、云服务器密码机、VPN、加密硬盘</h4>
				<el-row :gutter="12">
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SJM')">
							<p style="font-weight:500;font-size:14px;margin:0">模块SJM</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品139个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SJK')">
							<p style="font-weight:500;font-size:14px;margin:0">板卡SJK</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品346个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SJJ')">
							<p style="font-weight:500;font-size:14px;margin:0">整机SJJ</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品296个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SJT')">
							<p style="font-weight:500;font-size:14px;margin:0">系统SJT</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品45个</p>
						</div>
					</el-col>
				</el-row>
				<h4 class="title">R<span style="color:#faad14">认</span>证鉴别类：签名验签服务器、动态口令系统、认证网关</h4>
				<el-row :gutter="12">
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SRM')">
							<p style="font-weight:500;font-size:14px;margin:0">模块SRM</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品46个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SRK')">
							<p style="font-weight:500;font-size:14px;margin:0">板卡SRK</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品23个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SRJ')">
							<p style="font-weight:500;font-size:14px;margin:0">整机SRJ</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品78个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SRT')">
							<p style="font-weight:500;font-size:14px;margin:0">系统SRT</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品62个</p>
						</div>
					</el-col>
				</el-row>
				<h4 class="title">Z<span style="color:#faad14">证</span>书管理类：证书认证系统</h4>
				<el-row :gutter="12">
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SZM')">
							<p style="font-weight:500;font-size:14px;margin:0">模块SZM</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品12个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SZT')">
							<p style="font-weight:500;font-size:14px;margin:0">系统SZT</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品33个</p>
						</div>
					</el-col>
				</el-row>
				<h4 class="title">Y密<span style="color:#faad14">钥</span>管理类：密钥管理系统</h4>
				<el-row :gutter="12">
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SYM')">
							<p style="font-weight:500;font-size:14px;margin:0">模块SYM</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品2个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SYJ')">
							<p style="font-weight:500;font-size:14px;margin:0">整机SYJ</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品1个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SYT')">
							<p style="font-weight:500;font-size:14px;margin:0">系统SYT</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品65个</p>
						</div>
					</el-col>
				</el-row>
				<h4 class="title">F密码<span style="color:#faad14">防</span>伪类：电子印章系统、时间戳服务器</h4>
				<el-row :gutter="12">
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SFK')">
							<p style="font-weight:500;font-size:14px;margin:0">板卡SFK</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品1个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SFJ')">
							<p style="font-weight:500;font-size:14px;margin:0">整机SFJ</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品24个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SFT')">
							<p style="font-weight:500;font-size:14px;margin:0">系统SFT</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品48个</p>
						</div>
					</el-col>
				</el-row>
				<h4 class="title">H<span style="color:#faad14">综</span>合类：ATM密码应用系统</h4>
				<el-row :gutter="12">
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SHR')">
							<p style="font-weight:500;font-size:14px;margin:0">软件SHR</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品1个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SHM')">
							<p style="font-weight:500;font-size:14px;margin:0">模块SHM</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品81个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SHJ')">
							<p style="font-weight:500;font-size:14px;margin:0">整机SHJ</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品11个</p>
						</div>
					</el-col>
					<el-col :span="6" style="margin-bottom:12px">
						<div class="catb" @click="change_product_index(0,'SHT')">
							<p style="font-weight:500;font-size:14px;margin:0">系统SHT</p>
							<p style="font-size:12px;color:#999;margin:0;margin-top:5px">产品150个</p>
						</div>
					</el-col>
				</el-row>
				<!-- SJY ？？？
				WNG ？？？ -->
			</el-tab-pane>
		</el-tabs>

		<h4 style="padding:15px;color:#ffffffcc;">
			数据来源：service.scctc.org.cn<br />更新时间：2023年3月<br />认证产品：共3545个
		</h4>
	</div>
</template>
<script>
	export default {
		data() {
			return {};
		},
		mounted() {
			document.title = "商用密码认证产品";
		},
		methods: {
			change_product_index(tag, type) {
				this.$router.push({
					path: "/product_detail" + tag + "/" + type,
				});
			},
		},
	};
</script>